import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Google analyticsの設定
if (environment.gtagId !== undefined) {
  gtag('js', new Date());
  gtag('config', environment.gtagId, {
    app_name: 'スペース作成画面'
  });
}
else {
  console.log('gtagId is undefined');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
