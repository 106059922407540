import { Component, OnInit } from '@angular/core';
import { Auth, onIdTokenChanged } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-after-send-email-verification',
  templateUrl: './after-send-email-verification.component.html',
  styleUrls: ['./after-send-email-verification.component.css']
})
export class AfterSendEmailVerificationComponent implements OnInit {

  constructor(
    /** Auth */
    private _auth: Auth,
    /** ActivatedRoute */
    private _route: ActivatedRoute,
    /** Router */
    private _router: Router
  ) { }

  ngOnInit(): void {
    gtag('event', 'page_view', {
      page_title: 'Email認証後画面',
    });
  }

  onClickLogout() {
    // サインアウトする
    this._auth.signOut()
      // サインアウトしたら、元のページへ
      .then(() => window.location.href = `${environment.clientDomain}`);
  }
}
